import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
// import { AuthProvider } from "./context/Auth";
import PrivateRoute from "./routes/PrivateRoute";

import Client from "./modules/home/Home";
import Login from "./modules/login/Login";
import SignUp from "./modules/signup/SignUp";

const App = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <Client />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
