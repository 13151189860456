import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import LogOut from "../assets/images/logout.svg";
import UpshiftLogo from "../assets/images/upshift-logo.png";
import { getAuth, signOut } from "firebase/auth";
import { Navigate } from "react-router";

const Profile = () => {

  const handleSignOut = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        Navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  // React.useEffect(() => {
  //   const fetchProject = async () => {
  //     const db = app.firestore();
  //     const clientsRef = db.collection("projects");
  //     const data = await clientsRef
  //       .where("clients", "array-contains", app.auth().currentUser.email)
  //       .limit(1)
  //       .get();

  //     if (data.docs[0]) {
  //       if (data.docs[0].data().name) {
  //         const projectName = data.docs[0].data().name;
  //         setProject(projectName);
  //       }
  //     }
  //   };

  //   fetchProject();
  // }, []);

  return (
    <ProfileWrapper p={3}>
      <UpshiftLogoWrapper />
      <ContentWrapper p={3}>
        {/* <p>{app.auth().currentUser.displayName}</p> */}
        <Project>{"geen actief project"}</Project>
      </ContentWrapper>
      <LogOutButton onClick={handleSignOut}>
        <img src={LogOut} alt="overview" />
      </LogOutButton>
    </ProfileWrapper>
  );
};

const ProfileWrapper = styled(Flex)`
  align-items: center;
`;

const UpshiftLogoWrapper = styled(Box)`
  background-color: blue;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 20px 8px;
  background: #1e3b5c url(${UpshiftLogo}) no-repeat center center;
  background-size: contain;
`;

const ContentWrapper = styled(Box)`
  margin: 0 8px;
`;

const Project = styled.p`
  font-size: 0.8em;
`;

const LogOutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export default Profile;
