import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    min-width: 320px;
    padding: 0;
    width: 100%;
    background-color: #FCFCFC;
  }
  h2 {
    font-size: 1.6rem;
  }
  
  h3{
    margin: 20px 0 10px 0;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
    
  *:focus {
    outline: none;
  }

  h1, h2, h4, h5, h6, p {
    margin: 0;
  }

  p{
    font-weight: 300;
  }

  h1, h2{
    font-weight: 300;
    color: #1E3B5C;
    margin-bottom: 15px;
  }

  ul{
    font-weight: 300;
  }

  h3{
    color: #1E3B5C;
  }
    
  a {
    text-decoration: none;
    color: #1E3B5C;
    font-weight :300;
  }

  /* TODO: how to style scrollbar on firefox, edge, safari and older browsers ? */
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #F5F7FD;
  }
  ::-webkit-scrollbar-thumb {
    background: #DFE4EB;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #AFB8C4;
  }
`;

export default GlobalStyle;
