import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Flex } from "reflexbox";
import GlobalHead from "./GlobalHead";
import GlobalStyle from "./GlobalStyle";
import Profile from "../components/Profile";
import Footer from "../modules/footer/Footer";
import { SideNavBar } from "@upshiftbe/react-components";

const GlobalCollection = ({ children }) => (
  <>
    <GlobalHead />
    <GlobalStyle />
    <SideNavBar
      backgroundColor="white"
      boxShadow={"1px 0px 4px rgba(0, 0, 0, 0.1)"}
      breakPoint={"1200px"}
    >
      <div>
        <Profile />
        <Links p={3}>
          <StyledNavLink to={"/"} activeClassName="selected">
            Overview
          </StyledNavLink>
          <StyledNavLink to="/domains" activeClassName="selected">
            Domeinen
          </StyledNavLink>
          <StyledNavLink to="/emails" activeClassName="selected">
            E-mails
          </StyledNavLink>
          <StyledNavLink to="/website" activeClassName="selected">
            Website
          </StyledNavLink>
          <StyledNavLink to="/licenses" activeClassName="selected">
            Licenties
          </StyledNavLink>
          <StyledNavLink to="/webForms" activeClassName="selected">
            Formulieren
          </StyledNavLink>
          <StyledNavLink to="/invoices" activeClassName="selected">
            Facturen
          </StyledNavLink>
        </Links>
      </div>
      <div>
        <Small>upshift client admin - beta v0.0.1</Small>
      </div>
    </SideNavBar>
    <ContentHelper>
      <MaxWidthHelper>{children}</MaxWidthHelper>
      <Footer />
    </ContentHelper>
  </>
);

export default GlobalCollection;

const MaxWidthHelper = styled.div`
  max-width: 1200px;
  padding-top: 50px;
  margin: 0 auto;
`;

const ContentHelper = styled.div`
  @media screen and (min-width: 1200px) {
    margin-left: 270px;
  }

  padding: 25px;
  margin: 0 auto;
`;

const Links = styled(Flex)`
  flex-direction: column;
  .selected {
    background: #1e3b5c;
    color: white;
    box-shadow: 0px 0px 12px 0px #1e3b5c3b;
  }
`;

const StyledNavLink = styled(NavLink)`
  padding: 8px;
  font-weight: 400;
  margin: 5px 0;
  border-radius: 16px 6px;
  transition: 0.4 all;
  &:hover {
    box-shadow: 0px 0px 12px 0px #1e3b5c3b;
    transition: 0.4s all;
  }
`;

const Small = styled.p`
  font-size: 0.8em;
  padding: 5px 15px;
`;
